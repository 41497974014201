import React from "react";
import MobileNavbar from "./MobileNavbar";

const MobileTrainingPlacements = () => {
  return (
    <div>
      <MobileNavbar />
      <div class="page-header align-items-start min-vh-100">
        <video
              playsinline="playsinline"
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              loading="lazy"
              className="videos-fit"
            >
              <source src="../../assets/Videos/commingsoon.mp4" type="video/mp4" />
            </video>
            <div class="container my-auto">
      <div class="row">
        <div class="col-lg-12 m-auto text-center">
          <h1 class="display-1 text-bolder text-white">Coming Soon</h1>
          <h2 class="text-white">Exciting Updates Are on the Horizon – Stay Tuned!</h2>
          <p class="lead text-white">Indicating a new website or feature that will be launched shortly.</p>
         
        </div>
      </div>
    </div>
        </div>
    </div>
  );
};

export default MobileTrainingPlacements;
